import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './components/App/App';
import { BrowserRouter } from 'react-router-dom';
import 'animate.css';
import { MarketingSiteContextProvider } from 'contexts/MarketingSiteContext';
import { initializeAnalytics } from 'analytics';

initializeAnalytics();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <MarketingSiteContextProvider>
        <App />
      </MarketingSiteContextProvider>
    </BrowserRouter>
  </StrictMode>,
);
