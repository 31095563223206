import { Offer } from '../contexts/MarketingSiteContext/types';

interface fetchToProps {
  url: string;
}

export async function fetchWithOpts({ url }: fetchToProps): Promise<Response> {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const requestOptions = {
    method: 'GET',
    headers: headers,
  };

  return fetch(url, requestOptions);
}
export function getTo(url: string): Promise<Response> {
  return fetchWithOpts({ url });
}

export function scrollTo(id: string): void {
  return document?.getElementById(id)?.scrollIntoView({
    behavior: 'smooth',
  });
}

export const ISMOBILE = window?.matchMedia?.('only screen and (max-width: 768px)')?.matches;

export function getISeriesProductFamilyFromOffers(offers?: Offer[]) {
  const iSeriesProductFamilies: string[] = [];

  offers?.forEach((offer) => {
    offer.products?.forEach((product) => {
      const { iSeriesProductFamily } = product;
      if (iSeriesProductFamily && !iSeriesProductFamilies.includes(iSeriesProductFamily)) {
        iSeriesProductFamilies.push(iSeriesProductFamily);
      }
    });
  });
  return iSeriesProductFamilies;
}

export function isEnglishMarket(market: string): boolean {
  return market.slice(0, 2) === 'en';
}

export function getLanguage(localeCookie: string) {
  if (localeCookie.includes('en') || localeCookie.includes('es') || localeCookie.includes('fr')) {
    return localeCookie.slice(0, 2);
  } else {
    return '';
  }
}

export const formatPhoneNumber: (phoneNumberString: string | undefined | null) => string | undefined = (
  phoneNumberString,
) => {
  if (!phoneNumberString || phoneNumberString.length < 10) return undefined;

  const cleanPhoneString = phoneNumberString.replace(/[^\dx]/g, '');
  const phoneMatch = cleanPhoneString.match(/^(\d{10})(x?(\d+))?$/);

  if (!phoneMatch) return undefined;

  const [, phonePart, , extensionPart] = phoneMatch;
  const formattedNumber = `(${phonePart.substring(0, 3)}) ${phonePart.substring(3, 6)}-${phonePart.substring(6)}`;

  return extensionPart ? `${formattedNumber} Ext ${extensionPart}` : formattedNumber;
};

export function extractGroupSlug(url: string): string | null {
  const urlObj = new URL(url);
  const segments = urlObj.pathname.split('/').filter(Boolean);

  return segments[0] || null;
}
