import React, { useEffect, useMemo } from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import { LANGS_US, LANGS_CA, LANGSPRIMERICA } from '../layout/LangDropdown/LangDropdown';
import '../../styles/index.scss';
import '../../styles/styles.scss';
import '../../styles/font-face.scss';
import Layout from 'components/layout/layout';
import { marketingSiteNotFound, plansNotFoundOnLoad, productNotMapped } from 'utils/constants';
import Loading from 'components/Loading';
import { useMarketingSite } from 'contexts/MarketingSiteContext';
import { useOutlet } from 'react-router';

const Overview = React.lazy(() => import('../overview/Overview'));
const Legal = React.lazy(() => import('../legal/Legal'));
const IdShield = React.lazy(() => import('../idshield/IdShield'));
const SmallBiz = React.lazy(() => import('../smallbiz/SmallBiz'));
const IdShieldBiz = React.lazy(() => import('../idshieldbiz/IdShieldBiz'));
const Skinny = React.lazy(() => import('../skinny/Skinny'));
const Cdlp = React.lazy(() => import('../cdlp/Cdlp'));
const Enrollment = React.lazy(() => import('components/enrollment'));
const Offers = React.lazy(() => import('components/offers/Offers'));
const Errors = React.lazy(() => import('components/Errors'));
const MemberResources = React.lazy(() => import('../memberResources/MemberResources'));

const PATH_VALUES = window.location.pathname.split('/');

const ERROR_MAP: Record<string, string> = {
  [plansNotFoundOnLoad]: 'plans-not-found-on-load',
  [marketingSiteNotFound]: '404',
  [productNotMapped]: 'product-not-mapped-to-iseries-product-family',
};

const Wrapper = () => {
  const { groupSlug } = useParams();
  const outlet = useOutlet();
  return outlet || <Navigate to={`/${groupSlug}/overview`} />;
};

const App = () => {
  const { group, error, loading } = useMarketingSite();
  const { countryIdentifier, isPrimerica } = group || {};
  const langToMap = useMemo(
    () => (isPrimerica ? LANGSPRIMERICA : countryIdentifier === 'US' ? LANGS_US : LANGS_CA),
    [countryIdentifier, isPrimerica],
  );

  const mappedLangs = useMemo(
    () =>
      langToMap.map((lang) => {
        const [pre, post] = lang.value.split('-');
        // Second half of string must be uppercase to be displayed by ads_ dropdown
        return `${pre}-${post.toUpperCase()}`;
      }),
    [langToMap],
  );

  useEffect(() => {
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    ads_add_markets(ads_navigate_market, mappedLangs);
  }, []);

  return (
    <Layout>
      {error ? (
        <Errors error={ERROR_MAP[error as unknown as string] || '405'} />
      ) : loading ? (
        <Loading />
      ) : (
        <Routes>
          <Route element={<Wrapper />} path="/:groupSlug/*">
            <Route path="overview" element={<Overview />} />
            <Route path="legal" element={<Legal />} />
            <Route path="idshield" element={<IdShield />} />
            <Route path="small-business" element={<SmallBiz />} />
            <Route path="idshield-business" element={<IdShieldBiz />} />
            <Route path="skinny" element={<Skinny />} />
            <Route path="commercial-driver" element={<Cdlp />} />
            <Route path="offers" element={<Offers />} />
            <Route path="pricing" element={<Navigate to={'/' + PATH_VALUES[1] + '/enrollment'} />} />
            <Route path="enrollment" element={<Enrollment />} />
            <Route path="memberResources" element={<MemberResources />} />
          </Route>
        </Routes>
      )}
    </Layout>
  );
};

export default App;
