import * as React from 'react';
import { MarketingSiteContextSharedState } from 'contexts/MarketingSiteContext/types';
import { useCallback } from 'react';
import { GroupMarketingSites } from '@legalshield/frontend-commons';

const useStateFunctions = (setSharedState: React.Dispatch<React.SetStateAction<MarketingSiteContextSharedState>>) => {
  const login = useCallback(
    (username: string, password: string, marketingSite: GroupMarketingSites.MarketingSite) => {
      const { siteUsername, sitePassword } = marketingSite;
      if (username === siteUsername && password === sitePassword) {
        setSharedState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          loginError: undefined,
        }));
        sessionStorage.setItem('auth', 'true');
      } else {
        setSharedState((prevState) => ({
          ...prevState,
          loginError: 'Password does not match',
        }));
      }
    },
    [setSharedState],
  );

  return {
    login,
  };
};
export default useStateFunctions;
