export const isCheckoutV3 = (checkoutType: string): boolean => checkoutType === 'V3';

export const isInfoSite = (checkoutType: string): boolean => checkoutType === 'NONE';

export const readQueryParams = () => {
  const queryParamString = sessionStorage.getItem('gb_queryParams');
  if (!queryParamString) return {};
  const params = new URLSearchParams(queryParamString);
  const result: Record<string, string> = {};

  params.forEach((value: string, key: string) => {
    result[key.toLowerCase()] = value;
  });
  return result;
};
