import { useLocation } from 'react-router-dom';
import LangDropdown from '../LangDropdown/LangDropdown';
import './footer.scss';
import { Grid, GridCol, GridRow } from '@legalshield/adonis-ux-framework';
import { useMarketingSite } from 'contexts/MarketingSiteContext';
import { useEffect, useState } from 'react';
import { getAssociateByGroup } from 'services/groups';
import { IAssociate } from 'models/Associate';
import { formatPhoneNumber } from 'services/utils';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Footer = () => {
  const [associate, setAssociate] = useState<IAssociate>();
  const [loadingAssociate, setLoadingAssociate] = useState<boolean>(false);
  const { group, loading, marketingSite } = useMarketingSite();
  const { isNational, isPrimerica } = group || {};
  const {
    groupId,
    memberServiceEmail,
    memberServicePhoneNumber,
    memberServiceType,
    ownerId,
    serviceContactAssociateId,
  } = marketingSite || {};

  const location = useLocation();

  const fetchAssociate = async () => {
    if (!serviceContactAssociateId) {
      return;
    }

    setLoadingAssociate(true);
    try {
      const fetchedAssociate = await getAssociateByGroup(groupId, serviceContactAssociateId);
      setAssociate(fetchedAssociate);
    } catch (error) {
      console.warn(error);
    } finally {
      setLoadingAssociate(false);
    }
  };

  useEffect(() => {
    if (groupId) {
      fetchAssociate();
    }
  }, [groupId]);

  if (loading) {
    return null;
  }

  const copyright = FOOTER_M.copyright;
  const legal = isNational ? FOOTER_M.nlegalshield(isPrimerica) : FOOTER_M.legalshield(isPrimerica);

  const footerText = () => {
    switch (location.pathname) {
      case `/${ownerId}/overview`:
        return FOOTER_M.overview(isPrimerica);
      case `/${ownerId}/legal`:
        return legal;
      case `/${ownerId}/idshield`:
        return FOOTER_M.idshield(isPrimerica);
      case `/${ownerId}/commercial-driver`:
        return FOOTER_M.cdlp;
      case `/${ownerId}/skinny`:
        return FOOTER_M.skinny;
      case `/${ownerId}/small-business`:
        return FOOTER_M.smallbiz(isPrimerica);
      case `/${ownerId}/idshield-business`:
        return FOOTER_M.idshieldbiz;
      default:
        return FOOTER_M.overview(isPrimerica);
    }
  };

  const hasAssociateInfo =
    !!associate?.associateDisplayName &&
    (!!associate?.associatePreferredPhoneNumber ||
      !!associate?.associatePreferredEmail ||
      !!associate?.associatePhoneNumber ||
      !!associate?.associateEmail);

  return (
    <footer className="footerWrapper">
      <Grid classNames={['container']}>
        <GridRow classNames={['rows', 'containerWrapper', 'four-eight']}>
          <GridCol classNames={['col four', 'leftWrapper']}>
            <ul>
              {loadingAssociate ? (
                <li className="associateInfo">
                  <div className="heading">
                    <Skeleton width={150} />
                  </div>
                  <ul className="content">
                    <li>
                      <Skeleton width={300} />
                    </li>
                    <li>
                      <Skeleton width={300} />
                    </li>
                    <li>
                      <Skeleton width={300} />
                    </li>
                  </ul>
                </li>
              ) : hasAssociateInfo ? (
                <li className="associateInfo">
                  <div className="heading">{FOOTER_M.associate}</div>
                  <ul className="content">
                    <li>{associate?.associateDisplayName}</li>
                    <li>
                      <a href={`tel:${associate?.associatePreferredPhoneNumber || associate?.associatePhoneNumber}`}>
                        {formatPhoneNumber(associate?.associatePreferredPhoneNumber) ||
                          formatPhoneNumber(associate?.associatePhoneNumber) ||
                          ''}
                      </a>
                    </li>
                    <li>
                      <a href={`mailto:${associate?.associatePreferredEmail || associate?.associateEmail}`}>
                        {associate?.associatePreferredEmail || associate?.associateEmail || ''}
                      </a>
                    </li>
                  </ul>
                </li>
              ) : null}
              {memberServiceType ? (
                <li className="memberInfo">
                  <div className="heading">{FOOTER_M.member(isPrimerica)}</div>
                  <ul className="content">
                    {memberServicePhoneNumber ? (
                      <li>
                        <a href={`tel:${memberServicePhoneNumber}`}>{memberServicePhoneNumber}</a>
                      </li>
                    ) : null}
                    {memberServiceEmail ? (
                      <li>
                        <a href={memberServiceEmail}>{memberServiceEmail}</a>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              <li className="langSwitcher">
                <LangDropdown />
              </li>
            </ul>
          </GridCol>
          <GridCol classNames={['col eight', 'rightWrapper']}>
            <div className="disclaimer">
              <p dangerouslySetInnerHTML={{ __html: footerText() }} />
            </div>
            <ul className="rightMenu">
              <li>
                <span>{copyright.brand}</span>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href={copyright.terms}>
                  {FOOTER_M.terms}
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href={copyright.privacyPolicy}>
                  {FOOTER_M.privacy}
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href={copyright.ethicsCode}>
                  {FOOTER_M.ethics}
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href={copyright.soc3}>
                  {FOOTER_M.soc3}
                </a>
              </li>
            </ul>
          </GridCol>
        </GridRow>
      </Grid>
    </footer>
  );
};

export default Footer;
