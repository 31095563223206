import { Spinner } from '@legalshield/adonis-ux-framework';
import './index.scss';

export const Loading = () => {
  return (
    <div className="spinner">
      <Spinner spinnerSize="large" />
    </div>
  );
};

export default Loading;
