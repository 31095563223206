export const env = () => {
  const url = window.location.hostname;
  if (url.includes('dev-')) {
    return 'dev-';
  } else if (url.includes('uat-')) {
    return 'uat-';
  } else {
    return '';
  }
};
