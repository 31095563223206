import { useEffect, useState } from 'react';
import { getFullMarketingSiteData } from 'services/groups';
import { marketingSiteNotFound, plansNotFoundOnLoad, productNotMapped } from 'utils/constants';
import { MarketingSiteData } from 'contexts/MarketingSiteContext/types';
import { useLocation } from 'react-router-dom';
// import { sample_data } from './sample_data';

type SharedData = {
  data?: MarketingSiteData;
  error?: Error;
  loading: boolean;
};

const getDataError = (data: MarketingSiteData): Error | undefined => {
  const { isWebsiteEnabled } = data?.marketingSite || {};
  if (!data?.marketingSite?.id) {
    return new Error(marketingSiteNotFound);
  }

  //If the group has no offers, throw the new plans-not-found-on-load error.
  if (!data.offers?.[0]?.offerId) {
    return new Error(plansNotFoundOnLoad);
  }

  //If there is a product that is a product page that is not mapped, it will be an empty string in the list of product pages
  //an error should be thrown
  const iSeriesProductFamilies = new Set();
  for (const offer of data?.offers || []) {
    for (const product of offer.products || []) {
      iSeriesProductFamilies.add(product.iSeriesProductFamily);
    }
  }

  if (iSeriesProductFamilies?.has('')) {
    return new Error(productNotMapped);
  }

  if (!isWebsiteEnabled) {
    return new Error('Website unpublished');
  }
};

export type UseMarketingSiteDataReturn = {
  data: MarketingSiteData;
  error?: Error;
  loading: boolean;
};

export const useMarketingSiteData = () => {
  const [sharedData, setSharedData] = useState<SharedData>(() => {
    if (_marketingSiteData) {
      return {
        data: _marketingSiteData,
        // data: sample_data,
        error: getDataError(_marketingSiteData),
        loading: false,
      };
    } else {
      return {
        loading: true,
      } as SharedData;
    }
  });
  const { pathname } = useLocation();

  useEffect(() => {
    const loadData = async () => {
      try {
        const query = new URLSearchParams(location?.search);
        const groupSlug = pathname.split('/')[1];

        if (!_marketingSiteData) {
          const _data = await getFullMarketingSiteData(
            groupSlug,
            query?.get('region') ?? '',
            query?.get('market') ?? '',
          );
          setSharedData({
            data: _data,
            error: getDataError(_data),
            loading: false,
          });
        }
      } catch (error: unknown) {
        setSharedData((prev) => ({
          ...prev,
          error: error as Error,
          loading: false,
        }));
      }
    };
    loadData();
  }, []);

  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    data: sharedData.data!,
    error: sharedData.error,
    loading: sharedData.loading,
  } as UseMarketingSiteDataReturn;
};
