import { useEffect, useMemo, useRef, useState, ReactNode } from 'react';
import { MarketingSiteContext, MarketingSiteContextSharedState } from '..';
import getPPLSI from 'contexts/MarketingSiteContext/getPPLSI';
import { PPLSIPayload } from '@legalshield/frontend-commons';
import useStateFunctions from 'contexts/MarketingSiteContext/useStateFunctions';
import { useMarketingSiteData } from 'contexts/MarketingSiteContext/useMarketingSiteData';

export interface MarketingSiteContextProviderProps {
  children: ReactNode;
}

export const MarketingSiteContextProvider = (props: MarketingSiteContextProviderProps) => {
  const { children } = props;

  const { data, error, loading } = useMarketingSiteData();

  const { marketingSite } = data || {};
  const payPeriods = data?.group?.payPeriods || [];

  const _isAuthenticated = useRef(false);

  const [sharedState, setSharedState] = useState<MarketingSiteContextSharedState>(() => {
    if (sessionStorage.getItem('auth') === 'true' || (marketingSite && !marketingSite.isLoginEnabled)) {
      _isAuthenticated.current = true;
    }
    return {
      isAuthenticated: _isAuthenticated.current,
    };
  });

  useEffect(() => {
    if (marketingSite && !_isAuthenticated.current) {
      if (!marketingSite.isLoginEnabled || sessionStorage.getItem('auth') === 'true') {
        setSharedState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
        }));
        _isAuthenticated.current = true;
      }
    }
  }, [marketingSite]);

  const functions = useStateFunctions(setSharedState);

  const _pplsi = useRef<PPLSIPayload | undefined>(getPPLSI());
  const [pplsi] = useState<PPLSIPayload | undefined>(_pplsi.current);

  const groupBillingDivisions = useMemo(() => {
    const divisions: string[] = [];
    for (const payPeriod of payPeriods) {
      if (payPeriod?.division) {
        divisions.push(payPeriod?.division);
      }
    }
    return divisions;
  }, [data?.group?.payPeriods]);

  const isUniquePaymentFrequency = payPeriods.every((p) => p.period === payPeriods[0].period);

  return (
    <MarketingSiteContext.Provider
      value={{
        error,
        loading,
        pplsi,
        ...data,
        groupBillingDivisions,
        isUniquePaymentFrequency,
        ...functions,
        ...sharedState,
      }}>
      {children}
    </MarketingSiteContext.Provider>
  );
};
