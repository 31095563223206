import { getTo, getLanguage } from './utils';
import { Product } from '../../src/contexts/MarketingSiteContext';
import { ProductTierOffer } from './../components/enrollment/types';
import { IAssociate } from 'models/Associate';

async function getMarketingSiteData(groupSlug: string, region?: string, locale?: string): Promise<any> {
  const localeCookie = locale ? locale : _lsh_readCookie('market');
  const language = getLanguage(localeCookie ?? '');
  const url = `/v1/marketingSites/${groupSlug}?serviceArea=${region}&locale=${localeCookie}&language=${language}`;
  const res = await getTo(url);
  const data = await res.json();
  if (data.group) {
    data.group.isNational = data.group.isNational === 'true';
  }
  return data;
}

export async function getFullMarketingSiteData(groupSlug: string, region?: string, locale?: string): Promise<any> {
  return getMarketingSiteData(groupSlug, region ?? '', locale);
}

export async function getAssociateByGroup(groupId?: string, identityId?: string | null): Promise<IAssociate> {
  const res = await fetch(`/v1/groups/${groupId}?identityId=${identityId}`);
  const data = await res.json();
  return data;
}

export async function getPrimericaAgentById(agentID: string): Promise<any> {
  const res = await fetch(`/v1/primerica/primerica-agents/${agentID}`);
  const data = await res.json();
  return data;
}

export function productMonthlyInitialPrice(product: Product | ProductTierOffer | null): number {
  if (!product || !product.merchandizedTiers) {
    return 0;
  }
  const result =
    product.merchandizedTiers?.[0]?.prices?.find((p) => (p.billingPeriod || '') === 'MONTHLY')?.initialListPrice || 0;
  return result;
}

export function productYearlyInitialPrice(product: Product | ProductTierOffer | null): number {
  if (!product || !product.merchandizedTiers) {
    return 0;
  }
  const result =
    product.merchandizedTiers?.[0]?.prices?.find((p) => (p.billingPeriod || '') === 'ANNUAL')?.initialListPrice || 0;
  if (result === 0) {
    return productMonthlyInitialPrice(product) * 12;
  }
  return result;
}
