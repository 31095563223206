import Header from './header/header';
import Footer from './footer/footer';
import { ReactNode, Suspense } from 'react';
import ScrollToTop from 'components/layout/ScrollToTop';
import { Spinner } from '@legalshield/adonis-ux-framework';

export interface LayoutProps {
  children: ReactNode;
}

const Layout = (props: LayoutProps) => {
  const { children } = props;
  return (
    <div className="app">
      <ScrollToTop />
      <Header />
      <div>
        <main>
          <Suspense
            fallback={
              <div className="suspense_loading">
                <Spinner spinnerSize="large" />
              </div>
            }>
            {children}
          </Suspense>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
