import React, { useCallback, useEffect, useState } from 'react';
import Navigation from '../Navigation/Navigation';
import './header.scss';

const Header: React.FC = () => {
  // State that indicates the slideout is open, default to closed
  const [navOpen, setNavOpen] = useState(false);

  // Toggles the slideout
  const handleToggle = useCallback(() => {
    setNavOpen((navOpen) => !navOpen);
  }, []);

  useEffect(() => {
    // header setup

    // Configure the 'hook' into the hamburger item in the header/design service
    _lsh_set_hb_callback(handleToggle);

    const pickerDiv = document.getElementById('lsdsLanguagePicker');
    if (pickerDiv) {
      pickerDiv.style.marginRight = '-8px';
    }
    const wrapperDiv = document.getElementById('lsdsFixedHeader_wrapper');
    if (wrapperDiv) {
      wrapperDiv.style.fontSize = '100%';
    }
    //signInLink?.setAttribute('href', NAVIGATION.signIn);
  }, []);

  return (
    <nav className="navWrapper">
      <div className={`menu ${navOpen ? 'showing' : 'hidden'}`}>
        <Navigation closeNav={handleToggle} />
      </div>
    </nav>
  );
};

export default Header;
