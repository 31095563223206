import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import {
  LEGAL_PLAN,
  IDSHIELD_PLAN,
  IDSHIELD_BUSINESS_PLAN,
  SMALL_BUSINESS_PLAN,
  COMMERCIAL_DRIVER_PLAN,
  LEGAL_SMALL_BUSINESS_PLAN,
} from 'utils/constants';
import './Navigation.scss';
import { useMarketingSite } from 'contexts/MarketingSiteContext';
import { getISeriesProductFamilyFromOffers } from '../../../services/utils';

export interface NavigationProps {
  closeNav: () => void;
}

const Navigation = (props: NavigationProps) => {
  const { closeNav } = props;
  const { group, marketingSite, offers } = useMarketingSite();
  const { isPrimerica } = group || {};
  const { ownerId, checkoutType, displaySkinnyPage = false } = marketingSite || {};

  const iSeriesProductFamily = useMemo(() => getISeriesProductFamilyFromOffers(offers), [offers]);

  if (!marketingSite || !offers?.[0]?.offerId) {
    return null;
  }

  return (
    <div className="navigationWrapper">
      <ul onClick={closeNav}>
        <li>
          <NavLink to={`../${ownerId}/overview`} className="gms-nav-link">
            {TABS.overview}
          </NavLink>
        </li>
        {displaySkinnyPage ? (
          <li>
            <NavLink to={`../${ownerId}/skinny`} className="gms-nav-link">
              {TABS.skinny}
            </NavLink>
          </li>
        ) : (
          <>
            {iSeriesProductFamily.includes(LEGAL_PLAN) && (
              <li>
                <NavLink to={`../${ownerId}/legal`} className="gms-nav-link">
                  {TABS.legal}
                </NavLink>
              </li>
            )}
            {iSeriesProductFamily.includes(IDSHIELD_PLAN) && (
              <li>
                <NavLink to={`../${ownerId}/idshield`} className="gms-nav-link">
                  {TABS.identity}
                </NavLink>
              </li>
            )}
            {iSeriesProductFamily.some((product) =>
              [SMALL_BUSINESS_PLAN, LEGAL_SMALL_BUSINESS_PLAN].includes(product),
            ) && (
              <li>
                <NavLink to={`../${ownerId}/small-business`} className="gms-nav-link">
                  {TABS.smallbiz}
                </NavLink>
              </li>
            )}
            {iSeriesProductFamily.includes(IDSHIELD_BUSINESS_PLAN) && (
              <li>
                <NavLink to={`../${ownerId}/idshield-business`} className="gms-nav-link">
                  {TABS.idshieldbiz}
                </NavLink>
              </li>
            )}
            {iSeriesProductFamily.includes(COMMERCIAL_DRIVER_PLAN) && (
              <li>
                <NavLink to={`../${ownerId}/commercial-driver`} className="gms-nav-link">
                  {TABS.cdlp}
                </NavLink>
              </li>
            )}
          </>
        )}
        {(checkoutType != 'V1' || isPrimerica) && (
          <li>
            <NavLink to={`../${ownerId}/enrollment`} className="gms-nav-link">
              {TABS.enrollment}
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to={`../${ownerId}/memberResources`} className="gms-nav-link">
            {TABS.memberResources}
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
