import React, { useState, useRef, useEffect } from 'react';
import { SHIELD_BENEFITS_URL } from 'utils/constants';
import { env } from 'utils/env';
import { readQueryParams } from 'utils/services';
import './LangDropdown.scss';
import { useMarketingSite } from 'contexts/MarketingSiteContext';
import { useLocation } from 'react-router-dom';

const setCurrentLanguage = (market: string) => {
  switch (market) {
    case 'en-us':
      return 'English (US)';
    case 'en-ca':
      return 'English (CA)';
    case 'fr-ca':
      return 'French (CA)';
    case 'es-us':
      return 'Spanish (US)';
    default:
      return 'English (US)';
  }
};

export const LANGS_US = [
  {
    name: 'English (US)',
    value: 'en-us',
  },
  {
    name: 'Español (US)',
    value: 'es-us',
  },
];

export const LANGS_CA = [
  {
    name: 'English (CA)',
    value: 'en-ca',
  },
  {
    name: 'French (CA)',
    value: 'fr-ca',
  },
];

export const LANGSPRIMERICA = [
  {
    name: 'English (US)',
    value: 'en-us',
  },
  {
    name: 'Spanish (US)',
    value: 'es-us',
  },
  {
    name: 'English (CA)',
    value: 'en-ca',
  },
  {
    name: 'French (CA)',
    value: 'fr-ca',
  },
];

export type Lang = {
  name: string;
  value: string;
};

const LangDropdown = () => {
  const { group } = useMarketingSite();
  const { countryIdentifier, isPrimerica } = group || {};
  const [isActive, setIsActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(setCurrentLanguage(_lsh_readCookie('market')));
  const drop = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const handleCountryLanguageChange = (language: Lang) => {
    setIsActive(false);
    const agentParams = readQueryParams()?.agent ? `&agent=${readQueryParams().agent}` : '';
    if (selectedLanguage !== language?.name) {
      switch (language?.value) {
        case 'en-us':
          window.location.replace(`${SHIELD_BENEFITS_URL(env())}primerica?market=${language?.value}${agentParams}`);
          break;
        case 'en-ca':
          window.location.replace(`${SHIELD_BENEFITS_URL(env())}caprimerica?market=${language?.value}${agentParams}`);
          break;
        case 'fr-ca':
          window.location.replace(`${SHIELD_BENEFITS_URL(env())}caprimerica?market=${language?.value}${agentParams}`);
          break;
        case 'es-us':
          window.location.replace(`${SHIELD_BENEFITS_URL(env())}primerica?market=${language?.value}${agentParams}`);
          break;
        default:
          return '';
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (drop.current && drop.current.contains(e.target as Node)) {
        return;
      }
      setIsActive(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="langDropdownWrapper" ref={drop}>
      <div className="selected">
        <ul className={`submenu ${isActive ? 'showing' : 'hidden'}`}>
          {isPrimerica
            ? LANGSPRIMERICA.map((language) => (
                <li key={language.value} onClick={() => handleCountryLanguageChange(language)}>
                  {language.name}
                </li>
              ))
            : ((countryIdentifier || 'US') === 'US' ? LANGS_US : LANGS_CA).map((language) => (
                <li
                  key={language.value}
                  onClick={() => {
                    setSelectedLanguage(language.name);
                    setIsActive(false);

                    const searchParams = new URLSearchParams(window.location.search);
                    searchParams.set('market', language.value);

                    window.location.href = `${location.pathname}?${searchParams.toString()}`;
                  }}>
                  {language.name}
                </li>
              ))}
        </ul>
        <p
          onClick={(e) => {
            e.preventDefault();
            setIsActive((isActive) => !isActive);
          }}
          className="dropBtn">
          <i className="ls-globe" /> {selectedLanguage}
        </p>
      </div>
    </div>
  );
};

export default LangDropdown;
