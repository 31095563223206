import { getAnalyticsOptions, LSEnv, LSEnvTagManagerArgs, ThemisAnalytics } from '@legalshield/frontend-analytics';
import { CheckoutTypeEnum } from '@legalshield/frontend-commons/dist/sdk/group-marketing-sites';
import { extractGroupSlug, getISeriesProductFamilyFromOffers } from 'services/utils';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
  }
}

export const GTM_EVENT_NAME = {
  INITIAL_PAGE_DATA: 'initial_page_data',
  NO_GROUP_FOUND: 'no_group_found',
  TRACK_COMMERCIAL_DRIVER_PAGE: 'track_commercial_driver_page',
  TRACK_ENROLLMENT_PAGE: 'track_enrollment_page',
  TRACK_ID_SHIELD_FOR_BUSINESS_PAGE: 'track_id_shield_for_business_page',
  TRACK_ID_SHIELD_PAGE: 'track_id_shield_page',
  TRACK_LEGAL_PAGE: 'track_legal_page',
  TRACK_OVERVIEW_PAGE: 'track_overview_page',
  TRACK_RESOURCES_PAGE: 'track_resources_page',
  TRACK_SMALL_BUSINESS_PAGE: 'track_small_business_page',
  TRACK_SKINNY_PAGE: 'track_skinny_page',
};

export const GTM_ACTION_NAME = {
  CLICKED_CREATE_ACCOUNT_LINK: 'clicked_create_account_link',
  CLICKED_SIGN_IN_LINK: 'clicked_sign_in_link',
  CLICKED_IDSHIELD_MOBILE_APP_DOWNLOAD_LINK: 'clicked_idshield_mobile_app_download_link',
  CLICKED_LEGAL_MOBILE_APP_DOWNLOAD_LINK: 'clicked_legal_mobile_app_download_link',
  CLICKED_HOW_TO_ENROLL_CTA: 'clicked_how_to_enroll_cta',
  CLICKED_LEARN_MORE_LINK: 'clicked_learn_more_link',
  CLICKED_VIEW_VIDEO_LINK: 'clicked_view_video_link',
  CLICKED_FIND_LAW_FIRM_LINK: 'clicked_find_law_firm_link',
  CLICKED_MEMBER_PERKS_LINK: 'clicked_member_perks_link',
  WATCHED_IDSHIELD_MOBILE_APP_VIDEO: 'watched_idshield_mobile_app_video',
  WATCHED_LEGAL_MOBILE_APP_VIDEO: 'watched_legal_mobile_app_video',
};

interface PageEventMap {
  [key: string]: string;
}

export const pageEventMap: PageEventMap = {
  overview: GTM_EVENT_NAME.TRACK_OVERVIEW_PAGE,
  idShield: GTM_EVENT_NAME.TRACK_ID_SHIELD_PAGE,
  legal: GTM_EVENT_NAME.TRACK_LEGAL_PAGE,
  smallBiz: GTM_EVENT_NAME.TRACK_SMALL_BUSINESS_PAGE,
  idShieldBiz: GTM_EVENT_NAME.TRACK_ID_SHIELD_FOR_BUSINESS_PAGE,
  cdlp: GTM_EVENT_NAME.TRACK_COMMERCIAL_DRIVER_PAGE,
  skinny: GTM_EVENT_NAME.TRACK_SKINNY_PAGE,
};

const gtmId = 'GTM-KZ8H6SGL';
export const AppEnvsGTMConfigs: LSEnvTagManagerArgs = {
  [LSEnv.Local]: {
    auth: '9eNnBJC_fgIsWUn2SQJm3Q',
    gtmId,
    preview: 'env-69',
  },
  [LSEnv.Dev]: {
    auth: '9eNnBJC_fgIsWUn2SQJm3Q',
    gtmId,
    preview: 'env-69',
  },
  [LSEnv.Uat]: {
    auth: 'izhTTW2f-Zm8nwvHrUXbAg',
    gtmId,
    preview: 'env-70',
  },
  [LSEnv.Prod]: {
    auth: '8TdiYDDkn1e9CoIG_YBCeQ',
    gtmId,
    preview: 'env-1',
  },
};

let themisAnalytics: ThemisAnalytics;
export const initializeAnalytics = () => {
  themisAnalytics = new ThemisAnalytics(getAnalyticsOptions(window?.location?.hostname, AppEnvsGTMConfigs));
  themisAnalytics.initialize();

  if (_marketingSiteData) {
    trackEvent(GTM_EVENT_NAME.INITIAL_PAGE_DATA, {
      group_number: _marketingSiteData.group?.ownerId,
      group_name: _marketingSiteData.group?.name,
      group_effective_date: _marketingSiteData.group?.effectiveAt,
      group_pay_type: _marketingSiteData.group?.payType,
      industry_type: _marketingSiteData.group?.industryType,
      group_offer_count: _marketingSiteData.offers?.length,
      product_families: getISeriesProductFamilyFromOffers(_marketingSiteData?.offers),
      employee_count: _marketingSiteData.additionalGroupInformation?.numberOfEmployees || 0,
      active_member_count: _marketingSiteData.additionalGroupInformation?.totalActiveSubscriptions || 0,
      franchise_code: _marketingSiteData.group?.franchise?.code,
      website_type:
        _marketingSiteData?.marketingSite?.checkoutType === CheckoutTypeEnum.NONE ? 'Informational' : 'Enrollment',
    });
  } else {
    trackEvent(GTM_EVENT_NAME.NO_GROUP_FOUND, {
      group_name: extractGroupSlug(window.location.href) || 'Group name not found',
      group_not_found_url: window.location.href,
    });
  }
};

export const trackEvent = (eventName: string, eventDetails: Record<string, unknown> = {}) => {
  if (!themisAnalytics) {
    console.error('Analytics not initialized');
  }
  themisAnalytics?.triggerCustomEvent(eventName, {
    ...eventDetails,
  });
};

export const triggerEvent = (eventName: string) => {
  if (!themisAnalytics) {
    console.error('Analytics not initialized');
  }
  themisAnalytics?.triggerEvent(eventName);
};
